import React, { useEffect, useRef } from 'react';
import cs from 'classnames';
import s from './styles.module.scss';
import { useGradientAnimation } from './useGradientAnimation.hook';

export const Banner = () => {
    const [opened, setOpened] = React.useState(false);
    const canvasRef = useRef<any>();
    const canvasBgRef = useRef<any>();
    const { startAnimation } = useGradientAnimation();
    const { startAnimation: startAnimationBg, stopAnimation: stopAnimationBg } = useGradientAnimation();

    useEffect(() => {
        startAnimation(canvasRef?.current as HTMLCanvasElement, 0.01);
    }, []);

    useEffect(() => {
        if (opened) {
            startAnimationBg(canvasBgRef?.current as HTMLCanvasElement, 0.03);
        }
        return () => {
            stopAnimationBg();
        }
    }, [opened]);

    return (
        <div className={cs(s.wrapper, opened && s.opened)}>
            <div className={s.content}>
                {opened && (
                    <div className={s.bg}>
                        <canvas className={s.canvas} ref={canvasBgRef} width="32" height="32" />
                    </div>
                )}
                <div className={s.inner}>
                    <div className={cs(s.borders, opened && s.opened)}>
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div onClick={() => setOpened(true)}>
                            <div className={s.circle} />
                            <div className={s.blueSmall}>
                                <span>Discover More</span>
                                <canvas className={s.canvas} ref={canvasRef} width="32" height="32" />
                            </div>
                        </div>
                    </div>
                    <div className={s.title}>
                        We Charge Your Business with Frontend Innovations.
                    </div>
                    <div className={s.name}>
                        FE EXPERT
                    </div>
                    <div className={s.year}>
                        EST. 2015
                    </div>
                    <div className={s.text}>
                        Implement business logic, provide high performance, write clean, fast, reliable, and highly scalable code, use everything that good UX design is all about, consult on website launches, and audit existing ones — these superheroes DO exist.
                        <br />
                        We solve the front-end tasks of any business!
                    </div>
                    <button className={cs(s.start, s.blueButton)}>
                        Start Cooperation
                        <img width={19} src='/icons/arrow-down.svg' />
                    </button>
                    {opened && (
                        <>
                            <div className={s.technologies}>
                                <ul>
                                    <li>01<span>/</span>HTML5 / CSS3</li>
                                    <li>02<span>/</span>JAVASCRIPT</li>
                                    <li>03<span>/</span>React (Next.js)</li>
                                    <li>04<span>/</span>Typescript</li>
                                    <li>05<span>/</span>Web3JS</li>
                                    <li>06<span>/</span>ReactFlow</li>
                                    <li>07<span>/</span>Lexical</li>
                                    <li>08<span>/</span>WebRTC</li>
                                </ul>
                            </div>
                            <button onClick={() => setOpened(false)} className={cs(s.backButton, s.blueButton)}>
                                GO BACK
                            </button>
                        </>
                    )}
                </div>
            </div>
            {/*<div className={s.left}>*/}
            {/*    We Charge Your Business with Frontend Innovations!*/}
            {/*</div>*/}
            {/*<div className={s.right}>*/}
            {/*    Second*/}
            {/*</div>*/}
        </div>
    );
};
