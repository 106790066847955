import React from 'react';
import { Banner } from './components/banner';
import { AboutUs } from './components/about-us';

export const Home = () => {
    return (
        <div>
            <Banner />
            <AboutUs />
        </div>
    );
};
