import React from 'react';
import cs from 'classnames';
import s from './styles.module.scss';

export const AboutUs = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.bg} />
            <div className={s.content}>
                <div className={s.left}>
                    <div className={s.title}>
                        Get Ready to Take Your Project to a New Digital Level with Us
                    </div>
                    <div className={s.text}>
                        <p>
                            Why FE Expert? We're ready to be your trusted digital transformation partner and support IT changes. We're experienced advisors, creatives, visionaries, and techies. We are gurus in front-end technologies and use tons of resources to create structurally complex products.
                        </p>
                        <p>
                            Whatever brought you to us, whether it was work, play, or something in between, we understand the challenges you face and are ready to offer the best front-end solutions.
                        </p>
                        <p>
                            Transform your products with us!
                        </p>
                    </div>
                    <div className={s.blueLine} />
                </div>
                <div className={s.right}>
                    <div className={s.line} />
                    <div className={s.graphic}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="40" viewBox="0 0 45 40" fill="none">
                            <path d="M22.5 0L23.6081 25.6085C23.6187 25.8539 23.7451 26.0787 23.9469 26.2108L45 40L22.8388 28.1807C22.6264 28.0674 22.3736 28.0674 22.1612 28.1807L0 40L21.0531 26.2108C21.2549 26.0787 21.3813 25.8539 21.3919 25.6085L22.5 0Z" fill="#0494F9"/>
                        </svg>
                    </div>
                    <div className={s.textBlock}>
                        TEAM:
                        <br />
                        15+ employees
                    </div>

                    <div className={s.textBlock}>
                        Experience:
                        <br />
                        10 years
                    </div>

                    <div className={s.textBlock}>
                        PROJECT:
                        <br />
                        20+ implemented
                    </div>
                    <div className={s.textBlock}>
                        ABOUT:
                        <br />
                        FRONTEND EXPERT
                    </div>
                </div>
            </div>
        </div>
    );
};
