import React from 'react';
import s from './styles.module.scss';

export const Header = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.logo}>
                <img width={22} src="/logo.svg" alt="logo" />
                <span>FE EXPERT</span>
            </div>
            <div className={s.menu}>
                <ul>
                    <li><a href='#about'>About Us</a></li>
                    <li><a href='#we-decide'>We Decide</a></li>
                    <li><a href='#technologies'>Technologies</a></li>
                    <li><a href='#services'>Services</a></li>
                    <li><a href='#portfolio'>Portfolio</a></li>
                    <li><a href='#work-process'>Work Process</a></li>
                </ul>
            </div>
            <div className={s.contacts}>
                <span>
                    <a href='#contact-us'>Contact Us</a>
                </span>
            </div>
        </div>
    );
};
